import React from "react";
import { useNavigate } from "react-router-dom";

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const navigate = useNavigate();

  // Scroll to a specific section
  const scrollToSection = (id) => {
    const section = document.getElementById(id);
    if (section) {
      section.scrollIntoView({ behavior: "smooth" });
    }
    toggleSidebar(); // Close sidebar after scrolling
  };

  // Navigate to another page
  const handleNavigateToMenu = () => {
    toggleSidebar(); // Close sidebar before navigation
    navigate("/menu"); // Change to your Menu page route
  };

  return (
    <div
      className={`fixed top-0 right-0 h-full bg-[#723d12] text-white w-64 transition-transform transform ${
        isOpen ? "translate-x-0" : "translate-x-full"
      } z-50 shadow-lg items-center justify-center`}
    >
      <div className="flex flex-col mt-10 h-full py-8 space-y-6">
        <div
          className="flex flex-row items-center gap-4 cursor-pointer ml-10"
          onClick={() => scrollToSection("home")}
        >
          {/* Home Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 h-8 text-white"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M3 9.75L12 3l9 6.75V21H3V9.75z" />
            <path d="M9 21V12h6v9" />
          </svg>
          <span className="text-lg">Home</span>
        </div>
        <div
          className="flex flex-row items-center gap-4 cursor-pointer ml-10"
          onClick={handleNavigateToMenu}
        >
          {/* Menu Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 h-8 text-white"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="3" y1="12" x2="21" y2="12" />
            <line x1="3" y1="6" x2="21" y2="6" />
            <line x1="3" y1="18" x2="21" y2="18" />
          </svg>
          <span className="text-lg">Menu</span>
        </div>
        <div
          className="flex flex-row items-center gap-4 cursor-pointer ml-10"
          onClick={() => scrollToSection("about")}
        >
          {/* About Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-8 h-8 text-white"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="12" y1="16" x2="12" y2="12" />
            <line x1="12" y1="8" x2="12.01" y2="8" />
          </svg>
          <span className="text-lg">About</span>
        </div>
        <div
          className="flex flex-row items-center gap-4 cursor-pointer ml-10"
          onClick={() => scrollToSection("contact")}
        >
          {/* Contact Icon */}
          <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 122.88 122.27"
            className="w-8 h-8 text-white"
            fill="currentColor"
          >
            <g>
              <path d="M33.84,50.25c4.13,7.45,8.89,14.6,15.07,21.12c6.2,6.56,13.91,12.53,23.89,17.63c0.74,0.36,1.44,0.36,2.07,0.11 c0.95-0.36,1.92-1.15,2.87-2.1c0.74-0.74,1.66-1.92,2.62-3.21c3.84-5.05,8.59-11.32,15.3-8.18c0.15,0.07,0.26,0.15,0.41,0.21 l22.38,12.87c0.07,0.04,0.15,0.11,0.21,0.15c2.95,2.03,4.17,5.16,4.2,8.71c0,3.61-1.33,7.67-3.28,11.1 c-2.58,4.53-6.38,7.53-10.76,9.51c-4.17,1.92-8.81,2.95-13.27,3.61c-7,1.03-13.56,0.37-20.27-1.69 c-6.56-2.03-13.17-5.38-20.39-9.84l-0.53-0.34c-3.31-2.07-6.89-4.28-10.4-6.89C31.12,93.32,18.03,79.31,9.5,63.89 C2.35,50.95-1.55,36.98,0.58,23.67c1.18-7.3,4.31-13.94,9.77-18.32c4.76-3.84,11.17-5.94,19.47-5.2c0.95,0.07,1.8,0.62,2.25,1.44 l14.35,24.26c2.1,2.72,2.36,5.42,1.21,8.12c-0.95,2.21-2.87,4.25-5.49,6.15c-0.77,0.66-1.69,1.33-2.66,2.03 c-3.21,2.33-6.86,5.02-5.61,8.18L33.84,50.25L33.84,50.25L33.84,50.25z" />
            </g>
          </svg>
          <span className="text-lg">Contact</span>
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
