import React, { useEffect, useState } from "react";
import Header from "./Header";
import Carousel from "./Hero";
import image from "./image.png";
import Footer from "./MenuFooter";
import axiosInstance from "../../api";
import { FaHome } from "react-icons/fa";

const TestMenu = () => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [categories, setCategories] = useState([]); // State variable to store category data
  const [chefs, setChefs] = useState([]); // State to store fetched chefs' data
  const [menu, setMenu] = useState([]);
  const [menuData, setMenuData] = useState({});

  const handleCategoryClick = (category) => {
    setSelectedCategory(category === selectedCategory ? null : category);
  };

  // category
  useEffect(() => {
    const fetchCategory = async () => {
      try {
        const response = await axiosInstance.get("/api/category/get-category");
        setCategories(response.data.data); // Save the category data into state
      } catch (err) {
        console.log("Error" + err);
      } finally {
      }
    };

    fetchCategory(); // Call the fetch function on component mount
  }, []);

  // chef-suggestion
  useEffect(() => {
    const fetchChefs = async () => {
      try {
        const response = await axiosInstance.get("/api/chef/get-chef"); // Fetching data from the backend
        setChefs(response.data.data); // Save the fetched chefs' data in state
      } catch (err) {
      } finally {
      }
    };

    fetchChefs(); // Call the function to fetch chef data on component mount
  }, []);

  // today Menu

  const fetchTodayMenu = async () => {
    try {
      const today = new Date();

      // Format it as YYYY-MM-DD (local time)
      const year = today.getFullYear();
      const month = (today.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
      const day = today.getDate().toString().padStart(2, "0"); // Pad single digits with 0

      const formattedDate = `${year}-${month}-${day}`;

      const response = await axiosInstance.get(
        `/api/dish/get-dish-date/${formattedDate}`
      );
      setMenu(response.data[0].items); // Assuming the response contains the menu data
    } catch (error) {
      console.error("Error fetching today’s menu:", error);
    }
  };

  useEffect(() => {
    fetchTodayMenu();
  }, []);

  // common menu

  const [comMenu, setComMenu] = useState([]);
  const commMenu = async () => {
    try {
      const response = await axiosInstance.get("/api/menu/get-menu");
      setComMenu(response.data); // Assuming the response contains the menu data
      // Organize the menu data by category
      const organizedMenu = response.data.reduce((acc, item) => {
        if (!acc[item.category]) acc[item.category] = [];
        acc[item.category].push(item);
        return acc;
      }, {});
      setMenuData(organizedMenu); // Save the organized menu data based on category
    } catch (error) {
      console.error("Error fetching menu:", error);
    }
  };

  useEffect(() => {
    commMenu();
  }, []);

  return (
    <>
      <div className="bg-[#EBE1D5] text-[#5B2C06] font-sans">
        {/* Navbar */}
        <Header />

        {/* Header */}
        <Carousel />

        {/* Main Content */}
        <main className="container mx-auto px-4 md:px-10 lg:px-20 grid grid-cols-1 md:grid-cols-2 gap-8">
          {/* Left Side - Plats et Dessert */}
          <div>
            <h2 className="bg-[#5B2C06] text-white text-center py-4 mb-6 uppercase font-bold w-full flex items-center justify-center text-[15px] sm:text-[15px] md:text-[15px] lg:text-[20px] px-4 sm:px-6 md:px-8 lg:px-10">
              Plats Et Dessert Du Jour
            </h2>

            {menu.length > 0 &&
              menu.map((dish) => (
                <div key={dish._id} className="mb-6 m-2">
                  <div className="flex items-center space-x-4">
                    <h3 className="text-xl font-bold p-2">{dish.name}</h3>

                    {/* Display home icon if "home" is Yes */}
                    {dish.home === "Yes" && (
                      <div className="border border-[#713D11] px-4 py-2 rounded">
                        Home
                      </div>
                    )}

                    {/* Display Vegan text if "baigan" is Yes */}
                    {dish.baigan === "Yes" && (
                      <span className="ml-3 bg-[#713D11] text-white px-2 py-1 text-sm rounded">
                        Vegan
                      </span>
                    )}
                  </div>

                  {/* Display the price */}
                  <p className="font-bold text-[#5B2C06] p-2">
                    {dish.dprice ? (
                      <>
                        <span className="line-through">CHF {dish.price}</span>{" "}
                        CHF {dish.dprice}
                      </>
                    ) : (
                      `CHF ${dish.price}`
                    )}
                  </p>

                  {/* Display the description */}
                  <p className="text-[#713D11] p-2">{dish.description}</p>

                  {/* Display the allergies */}
                  <p className="text-[#713D11] p-2">
                    Allergies: {dish.allergies}
                  </p>

                  {/* Display the baigan value */}

                  {/* Check if the dish is vegetarian and display (V) */}
                  {dish.isType === "dish" && dish.vegetarian && (
                    <span className="text-sm font-medium p-2 text-[#713D11]">
                      (V)
                    </span>
                  )}

                  {/* Line Separator */}
                  <div className="border-t mt-4 border-[#713D11]"></div>
                </div>
              ))}
          </div>

          {/* Right Side - Suggestions du Chef */}
          <div>
            <h2 className="bg-[#5B2C06] text-white text-center py-4 mb-6 uppercase font-bold w-full flex items-center justify-center text-[15px] sm:text-[15px] md:text-[15px] lg:text-[20px] px-4 sm:px-6 md:px-8 lg:px-10">
              Suggestions Du Chef
            </h2>

            {chefs.length > 0 &&
              chefs.map((suggestion) => (
                <div key={suggestion._id} className="mb-6 p-5">
                  <div className="flex items-center space-x-4">
                    <h3 className="text-xl font-bold p-2">{suggestion.name}</h3>
                    {suggestion.category === "Yes" && (
                      <div className="border border-[#713D11] px-4 py-2 rounded">
                        Home
                      </div>
                    )}
                  </div>
                  {suggestion.vegetarian && (
                    <span className="text-sm p-2 font-medium text-[#5B2C06]">
                      (V)
                    </span>
                  )}
                  <p className="font-bold p-2 text-[#5B2C06]">
                    CHF {suggestion.price} (v)
                  </p>
                  <p className="text-[#713D11] p-2">{suggestion.description}</p>
                  <p className="text-[#713D11] p-2">
                    Allergies : {suggestion.allergies}
                  </p>

                  {/* Line Separator */}
                  <div className="border-t p-2 mt-4 border-[#5B2C06]"></div>
                </div>
              ))}
          </div>
        </main>
      </div>

      {/* Section Content */}
      <div className="bg-[#EBE1D5] text-[#5B2C06] font-sans min-h-screen ml-0 px-4 md:px-10 lg:px-20">
        <div className="overflow-x-auto whitespace-nowrap bg-[#EBE1D5] text-white">
          <div className="flex justify-center px-4">
            {" "}
            {/* Center the buttons */}
            {categories.length > 0 &&
              categories.map((category, ind) => (
                <button
                  key={ind}
                  onClick={() => handleCategoryClick(category)}
                  className={`py-3 px-6 text-[18px] md:text-[20px] lg:text-[22px] focus:outline-none border-2 border-[#5B2C06] hover:bg-[#5B2C06] hover:text-white hover:border-[#5B2C06] ${
                    selectedCategory === category
                      ? "bg-[#713D11] text-white font-bold"
                      : "text-[#5B2C06] border-[#5B2C06]"
                  }`}
                >
                  {category.name}
                </button>
              ))}
          </div>
        </div>

        {/* Section Content */}
        <div className="px-4 py-1">
          <div className="w-full space-y-0.5">
            {categories.length > 0 &&
              categories.map((category, ind) => (
                <div key={ind} className="relative">
                  {/* Accordion Header */}
                  <button
                    onClick={() => handleCategoryClick(category)}
                    className={`w-full text-center py-4 px-6 bg-[#713D11] text-white font-bold flex justify-center items-center ${
                      selectedCategory === category
                        ? "bg-[#713D11]"
                        : "hover:bg-[#713D11]"
                    }`}
                  >
                    <span>{category.name}</span>
                  </button>

                  {selectedCategory === category && (
                    <div className="p-4 bg-[#EBE1D5] relative flex flex-col md:flex-row">
                      {/* Menu List for selected category */}
                      <div className="w-full md:w-3/4 pr-5">
                        {menuData[category.name] &&
                        menuData[category.name].length > 0 ? (
                          menuData[category.name].map((item) => (
                            <div key={item._id} className="mb-6">
                              <div className="flex items-center">
                                <h3 className="text-2xl font-bold">
                                  {item.name}
                                </h3>

                                {/* Show "Home" box if menuType is "Yes" */}
                                {item.menuType === "Yes" && (
                                  <div className="border border-[#713D11] px-2 py-2 rounded">
                                    Home
                                  </div>
                                )}

                                {item.baigan === "Yes" && (
                                  <span className="ml-3 bg-[#713D11] text-white px-2 py-1 text-sm rounded">
                                    Vegan
                                  </span>
                                )}
                              </div>

                              {/* Show dprice with strikethrough if available */}
                              {item.dprice ? (
                                <div className="flex items-center space-x-3">
                                  {" "}
                                  {/* Add space between the prices */}
                                  <p className="text-[#5B2C06] font-bold text-xl line-through">
                                    CHF {item.dprice}
                                  </p>
                                  <p className="text-[#5B2C06] font-bold text-xl">
                                    CHF {item.price}
                                  </p>
                                </div>
                              ) : (
                                // Show regular price if dprice is not available
                                item.price && (
                                  <p className="text-[#5B2C06] font-bold text-xl">
                                    CHF {item.price}
                                  </p>
                                )
                              )}

                              {/* Show description */}
                              <p className="text-[#713D11] text-lg">
                                {item.description}
                              </p>

                              {/* Allergies info */}
                              {item.allergies && (
                                <p className="text-sm text-[#713D11]">
                                  <span>Allergies:</span> {item.allergies}
                                </p>
                              )}

                              <div className="border-t mt-4 border-[#713D11] w-full"></div>
                            </div>
                          ))
                        ) : (
                          <p className="text-center ml-14 text-lg text-gray-500 flex items-center justify-center h-full">
                            No menu found
                          </p>
                        )}
                      </div>

                      {/* Image Section (Optional) */}
                      {menuData[category.name] &&
                        menuData[category.name].length > 0 &&
                        image && (
                          <div className="w-full md:w-1/4 hidden md:block relative">
                            <div className="w-full h-full overflow-hidden relative">
                              <img
                                src={image}
                                alt="Menu"
                                className="object-cover w-[40%] sm:w-[50%] md:w-[60%] lg:w-[70%] h-auto absolute right-0 top-0"
                              />
                            </div>
                          </div>
                        )}
                    </div>
                  )}
                </div>
              ))}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default TestMenu;
