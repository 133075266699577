import React from "react";

function Carousel() {
  return (
    <>
      <header className="text-center py-10">
        <h1 className="text-4xl md:text-6xl font-bold tracking-widest">MENU</h1>
      </header>
    </>
  );
}

export default Carousel;
