import React, { useEffect, useState } from "react";
import Navbar from "../components/Sidebar/Sidebar";
import axios from "axios";
import { toast } from "react-toastify";
import * as XLSX from "xlsx";
import { FaTrash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import ConfirmationModal from "./Confirmationmodel";
import axiosInstance from "../api";

const AdminContact = () => {
  const [contacts, setContacts] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [itemsPerPage] = useState(10); 
  const navigate = useNavigate();

  const fetchDataValid = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/auth/validateToken`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.isValid) {
        return;
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error during token validation:", error);
      navigate("/login");
    }
  };

  useEffect(() => {
    fetchDataValid();
  }, []);

  const fetchContacts = async () => {
    try {
      setLoading(true);
      const response = await axiosInstance.get(
        "/api/contact/all"
      );
      setContacts(response.data.data || []);
    } catch (error) {
      toast.error("Failed to fetch contacts");
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const deleteContact = async (id) => {
    try {
      await axiosInstance.delete(
        `/api/contact/delete/${id}`
      );
      toast.success("Contact deleted successfully");
      fetchContacts();
    } catch (error) {
      toast.error("Failed to delete contact");
      console.error(error);
    }
  };


  
  const deleteAllContacts = async () => {
    try {
      await axiosInstance.delete(
        `/api/contact/delete-all`
      );
      toast.success("All contacts deleted successfully");
      setContacts([]);
      setIsDeleteModalOpen(false); 
    } catch (error) {
      toast.error("Failed to delete all contacts");
      console.error(error);
    }
  };

  const exportToExcel = () => {
    const worksheet = XLSX.utils.json_to_sheet(contacts);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Contacts");
    XLSX.writeFile(workbook, "contacts.xlsx");
  };

  useEffect(() => {
    fetchContacts();
  }, []);

  const filteredContacts = contacts.filter(
    (contact) =>
      contact.email.toLowerCase().includes(searchQuery.toLowerCase()) ||
      contact.phone.toLowerCase().includes(searchQuery.toLowerCase())
  );

  // Pagination logic
  const indexOfLastContact = currentPage * itemsPerPage;
  const indexOfFirstContact = indexOfLastContact - itemsPerPage;
  const currentContacts = filteredContacts.slice(
    indexOfFirstContact,
    indexOfLastContact
  );

  const pageCount = Math.ceil(filteredContacts.length / itemsPerPage);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div>
      <Navbar />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-gray-200 rounded-lg dark:border-gray-400 mt-14">
          <div className="flex justify-between items-center mb-4">
            <input
              type="text"
              placeholder="Search by email"
              className="border border-gray-700 rounded-lg p-2 w-1/3"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
            <div className="space-x-2">
              <button
                className="px-4 py-2 bg-black text-white rounded-md mr-2"
                // onClick={deleteAllContacts}
                onClick={() => setIsDeleteModalOpen(true)} 
              >
                Delete All
              </button>
              <button
                className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0] "
                onClick={exportToExcel}
              >
                Download Excel
              </button>
            </div>
          </div>

          {loading ? (
            <p className="text-center">Loading...</p>
          ) : filteredContacts.length === 0 ? (
            <p className="text-center">No contacts found.</p>
          ) : (
            <div className="overflow-x-auto">
              <table className="table-auto w-full text-sm text-left text-gray-500 dark:text-gray-400">
                <thead className="text-xs text-black uppercase bg-gray-100 dark:bg-gray-300 dark:text-black-400">
                  <tr>
                    <th className="px-6 py-3">Email</th>
                    <th className="px-6 py-3">Phone</th>
                    <th className="px-6 py-3">Message</th>
                    <th className="px-6 py-3 text-center">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {currentContacts.map((contact) => (
                    <tr
                      key={contact._id}
                      className="bg-white border-b dark:bg-white-800 dark:border-gray-700"
                    >
                      <td className="px-6 py-4 text-black">{contact.email}</td>
                      <td className="px-6 py-4 text-black">{contact.phone}</td>
                      <td className="px-6 py-4 text-black">
                        {contact.message}
                      </td>
                      <td className="px-6 py-4 text-center">
                        <button onClick={() => deleteContact(contact._id)}>
                          <FaTrash className="w-5 h-5" />
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}

          {/* Pagination Controls */}
          <div className="flex justify-center mt-4">
            <button
              className="px-4 py-2 bg-gray-500 text-white rounded-lg"
              disabled={currentPage === 1}
              onClick={() => handlePageChange(currentPage - 1)}
            >
              Previous
            </button>
            <span className="px-4 py-2">{`${currentPage} / ${pageCount}`}</span>
            <button
              className="px-4 py-2 bg-gray-500 text-white rounded-lg"
              disabled={currentPage === pageCount}
              onClick={() => handlePageChange(currentPage + 1)}
            >
              Next
            </button>
          </div>
        </div>
      </div>
       {/* Confirmation Modal */}
      <ConfirmationModal 
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)} 
        onConfirm={deleteAllContacts} 
      />
    </div>
  );
};

export default AdminContact;
