import React, { useEffect, useState } from "react";
import { Images } from "../../assets";
import { useNavigate } from "react-router-dom";
import { FaArrowUp, FaHome } from "react-icons/fa";
import axiosInstance from "../../api";
const Header = () => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [showScrollButton, setShowScrollButton] = useState(false);

  const handleScroll = () => {
    if (window.scrollY > 300) {
      setShowScrollButton(true);
    } else {
      setShowScrollButton(false);
    }
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const [suggestions, setSuggestions] = useState([]);

  const fetchSuggestions = async () => {
    try {
      const response = await axiosInstance.get(`/api/category/get-category`);
      setSuggestions(response.data.data);
    } catch (error) {
      console.error("Error fetching suggestions:", error);
    }
  };

  useEffect(() => {
    fetchSuggestions();
  }, []);

  const styles = {
    curvedTextStyle: {
      fontWeight: "bold",
      fill: "white",
      fontFamily: "'Chunk Five', 'Roboto Slab', serif",
      textTransform: "uppercase",
      letterSpacing: "0px",
    },
  };

  return (
    <div>
      <nav className="bg-[#5B2C06] text-white p-4 flex items-center justify-between">
        <a href="/" className="logo-container flex justify-center items-center w-auto h-[152px]">
          <img
            className="logo w-12 sm:w-14 md:w-16 lg:w-18 xl:w-18 h-[152px] object-contain"
            src={Images.MAIN_LOGO}
            alt="Logo"
          />
        </a>

        <button
          onClick={() => setIsSidebarOpen(!isSidebarOpen)}
          className="absolute top-4 right-4 text-white px-4 py-2 rounded-md flex items-center transition-all z-50"
        >
          {/* SVG Icon for Menu */}
          <svg
            className="w-8 h-8"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M3 12h18M3 6h18M3 18h18" />
          </svg>
        </button>
    </nav>

      <div className="relative w-full h-auto bg-black">
        {/* Sidebar */}
        <div
          className={`fixed top-0 right-0 h-full w-64 bg-[#f5e2cb] shadow-md transform ${
            isSidebarOpen ? "translate-x-0" : "translate-x-full"
          } transition-transform duration-300 z-50`}
        >
          {/* Close Button */}
          <button
            onClick={() => setIsSidebarOpen(false)}
            className="absolute top-4 right-4 bg-white text-black px-2 py-1 rounded-full shadow-md hover:bg-gray-200"
          >
            {/* SVG Icon for Close */}
            <svg
              className="w-6 h-6"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
            >
              <path d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>

          <div className="flex flex-col p-6 text-white">
            <button
              onClick={() => {
                navigate("/");
                setIsSidebarOpen(false);
              }}
              className="text-lg font-medium py-2 mt-8 px-4 text-black rounded-lg transition-all"
            >
              {/* SVG Icon for Home */}
              <svg
                className="w-5 h-5 text-black inline-block mr-2"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path d="M3 12l2 2 6-6 6 6 2-2M5 17h14a2 2 0 0 1 2 2v2a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-2a2 2 0 0 1 2-2z" />
              </svg>
              Home
            </button>
            <button
              onClick={() => {
                navigate("/menu");
                setIsSidebarOpen(false);
              }}
              className="text-lg font-medium py-2 mt-8 px-4 text-black rounded-lg transition-all"
            >
              <svg

            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 text-black inline-block mr-2"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="3" y1="12" x2="21" y2="12" />
            <line x1="3" y1="6" x2="21" y2="6" />
            <line x1="3" y1="18" x2="21" y2="18" />
          </svg>
              Menu
            </button>
            <button
              onClick={() => {
                navigate("/");
                setIsSidebarOpen(false);
              }}
              className="text-lg font-medium py-2 mt-8 px-4 text-black rounded-lg transition-all"
            >
              <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-5 h-5 text-black inline-block mr-2"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10" />
            <line x1="12" y1="16" x2="12" y2="12" />
            <line x1="12" y1="8" x2="12.01" y2="8" />
          </svg>
              About
            </button>
            <button
              onClick={() => {
                navigate("/");
                setIsSidebarOpen(false);
              }}
              className="text-lg font-medium py-2 mt-8 px-4 text-black rounded-lg transition-all"
            >
              <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            viewBox="0 0 122.88 122.27"
            className="w-5 h-5 text-black inline-block mr-2"
            fill="currentColor"
          >
            <g>
              <path d="M33.84,50.25c4.13,7.45,8.89,14.6,15.07,21.12c6.2,6.56,13.91,12.53,23.89,17.63c0.74,0.36,1.44,0.36,2.07,0.11 c0.95-0.36,1.92-1.15,2.87-2.1c0.74-0.74,1.66-1.92,2.62-3.21c3.84-5.05,8.59-11.32,15.3-8.18c0.15,0.07,0.26,0.15,0.41,0.21 l22.38,12.87c0.07,0.04,0.15,0.11,0.21,0.15c2.95,2.03,4.17,5.16,4.2,8.71c0,3.61-1.33,7.67-3.28,11.1 c-2.58,4.53-6.38,7.53-10.76,9.51c-4.17,1.92-8.81,2.95-13.27,3.61c-7,1.03-13.56,0.37-20.27-1.69 c-6.56-2.03-13.17-5.38-20.39-9.84l-0.53-0.34c-3.31-2.07-6.89-4.28-10.4-6.89C31.12,93.32,18.03,79.31,9.5,63.89 C2.35,50.95-1.55,36.98,0.58,23.67c1.18-7.3,4.31-13.94,9.77-18.32c4.76-3.84,11.17-5.94,19.47-5.2c0.95,0.07,1.8,0.62,2.25,1.44 l14.35,24.26c2.1,2.72,2.36,5.42,1.21,8.12c-0.95,2.21-2.87,4.25-5.49,6.15c-0.77,0.66-1.69,1.33-2.66,2.03 c-3.21,2.33-6.86,5.02-5.61,8.18L33.84,50.25L33.84,50.25L33.84,50.25z" />
            </g>
          </svg>
          Contact
            </button>
          </div>
        </div>

        {/* Back to Top Button */}
        {showScrollButton && (
          <button
            onClick={scrollToTop}
            className="fixed bottom-4 right-4 bg-[#5C4033] text-white p-4 rounded-full shadow-lg hover:bg-[#4E3629] transition-all z-50"
            aria-label="Scroll to Top"
          >
            <FaArrowUp className="text-2xl" />
          </button>
        )}
      </div>
    </div>
  );
};

export default Header;
