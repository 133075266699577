import React, { useEffect, useState } from "react";
import Navbar from "../components/Sidebar/Sidebar";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { FaEdit, FaTrash } from "react-icons/fa";
import { toast } from "react-toastify";
import axiosInstance from "../api";

const DishDescription = () => {
  const { date } = useParams();
  const [menus, setMenus] = useState([]);
  const [allDeleted, setAllDeleted] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const navigate = useNavigate();
  const fetchDataValid = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      navigate("/login");
      return;
    }

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/auth/validateToken`,
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.data.isValid) {
        return;
      } else {
        navigate("/login");
      }
    } catch (error) {
      console.error("Error during token validation:", error);
      navigate("/login");
    }
  };

  const handleDeleteAllModalOpen = () => {
    setIsDeleteModalOpen(true);
  };

  const handleDeleteAll = async () => {
    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("You must log in to perform this action.");
      navigate("/login");
      return;
    }

    try {
      const response = await axiosInstance.delete(
        `/api/dish/delete-all/${date}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 200) {
        toast.success("All menu items have been deleted successfully.");
        setMenus([]); // Clear the UI data
        setAllDeleted(true); // Set allDeleted state to true
        setIsDeleteModalOpen(false); // Close the modal after successful deletion
      } else {
        toast.error("Failed to delete all menu items.");
      }
    } catch (error) {
      console.error("Error deleting all menus:", error);
      toast.error("An error occurred while deleting all menus.");
    }
  };

  useEffect(() => {
    fetchDataValid();
  }, []);
  const [isAddMenuModalOpen, setAddMenuModalOpen] = useState(false);
  const [menuData, setMenuData] = useState({
    date: "",
    dishes: [
      {
        name: "",
        price: "",
        description: "",
        dprice: "",
        allergies: "",
        home: "",
        baigan: "",
      },
    ],
    desserts: [
      {
        name: "",
        price: "",
        description: "",
        dprice: "",
        allergies: "",
        home: "",
        baigan: "",
      },
    ],
  });

  const handleDishChange = (index, field, value, type) => {
    setMenuData((prev) => {
      const updatedItems = [...prev[type]];
      updatedItems[index][field] = value;
      return { ...prev, [type]: updatedItems };
    });
  };

  const addNewRow = (type) => {
    if (type === "dishes" && menuData.dishes.length < 3) {
      setMenuData({
        ...menuData,
        dishes: [
          ...menuData.dishes,
          {
            name: "",
            price: "",
            description: "",
            allergies: "",
            home: "",
            baigan: "",
          },
        ],
      });
    } else if (type === "desserts" && menuData.desserts.length === 0) {
      setMenuData({
        ...menuData,
        desserts: [
          {
            name: "",
            price: "",
            description: "",
            allergies: "",
            home: "",
            baigan: "",
          },
        ],
      });
    } else {
      if (type === "dishes" && menuData.dishes.length >= 3) {
        toast.error("You can only add up to 3 dishes.");
      } else if (type === "desserts" && menuData.desserts.length > 0) {
        toast.error("You can only add 1 dessert.");
      }
    }
  };

  const removeRow = (index, type) => {
    if (type === "dishes") {
      const updatedDishes = menuData.dishes.filter((_, i) => i !== index);
      setMenuData({ ...menuData, dishes: updatedDishes });
    } else if (type === "desserts") {
      setMenuData({ ...menuData, desserts: [] });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const token = localStorage.getItem("token");

    if (!token) {
      toast.error("You must log in to perform this action.");
      navigate("/login");
      return;
    }

    try {
      const payload = {
        date: new Date(menuData.date).toISOString(),
        items: [
          ...menuData.dishes.map((item) => ({ ...item, isType: "dish" })),
          ...menuData.desserts.map((item) => ({ ...item, isType: "dessert" })),
        ],
      };

      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/dish/create`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (response.status === 201 || response.status === 200) {
        toast.success("Menu saved successfully!");
        setAddMenuModalOpen(false);
        setMenuData({
          dishes: [
            {
              name: "",
              price: "",
              description: "",
              dprice: "",
              allergies: "",
              home: "",
              baigan: "",
            },
          ],
          desserts: [
            {
              name: "",
              price: "",
              description: "",
              dprice: "",
              allergies: "",
              home: "",
              baigan: "",
            },
          ],
        });
        fetchMenus();
      } else {
        toast.error("Failed to save the menu.");
      }
    } catch (error) {
      console.error("Error saving menu:", error);
      toast.error("An error occurred while saving the menu.");
    }
  };

  const fetchMenus = async () => {
    try {
      const response = await axiosInstance.get(
        `/api/dish/get-dish-date/${date}`
      );
      const menus = response.data[0]?.items || [];
      console.log(response.data[0]?.items);
      setMenus(menus);
      setAllDeleted(menus.length === 0);
    } catch (err) {
      console.error("Failed to fetch menu data:", err);
    }
  };

  useEffect(() => {
    setMenuData((prev) => ({
      ...prev,
      date: date,
    }));

    fetchMenus();
  }, [date]);

  const handleDelete = async (data) => {
    try {
      await axiosInstance.delete(`/api/dish/delete/${data._id}/${date}`);
      toast.success(`${data.name} has been deleted successfully.`);
      fetchMenus();
    } catch (error) {
      console.error("Error deleting menu:", error);
      toast.error("Failed to delete the menu.");
    }
  };

  const [isEditMenuModalOpen, setEditMenuModalOpen] = useState(false);
  const [editmenuData, editsetMenuData] = useState({
    name: "",
    price: "",
    description: "",
    menuType: "",
    dprice: "",
    allergies: "",
    home: "",
    baigan: "",
  });
  const handleEditChange = (e) => {
    const { name, value } = e.target;
    editsetMenuData((prev) => ({ ...prev, [name]: value }));
  };
  const [editValue, setEditValue] = useState({});
  const handleEdit = (item) => {
    setEditValue(item);
    editsetMenuData(item);
    setEditMenuModalOpen(true);
  };

  const handleEditSubmit = async () => {
    try {
      const response = await axiosInstance.put(
        `/api/dish/edit/${date}/${editValue._id}`,
        editmenuData
      );
      toast.success("Menu updated successfully!");
      fetchMenus();
      setEditMenuModalOpen(false);
    } catch (error) {
      console.error("Error updating menu:", error);
      toast.error("Failed to update menu!");
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const paginate = (data) => {
    const startIndex = (currentPage - 1) * itemsPerPage;
    return data.slice(startIndex, startIndex + itemsPerPage);
  };
  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
    setCurrentPage(1);
  };

  const renderTable = (data) => {
    const filteredMenu = data.filter((item) =>
      item.name.toLowerCase().includes(searchQuery.toLowerCase())
    );
    const paginatedMenu = paginate(filteredMenu);

    return (
      <div className="mb-8">
        <h2 className="text-lg font-semibold mb-4 capitalize">Menu</h2>
        <div className="overflow-x-auto relative">
          <table className="w-full text-sm text-left text-black-500 dark:text-black-400">
            <thead className="text-xs text-black-700 uppercase bg-gray-50 dark:bg-gray-200 dark:text-black-400">
              <tr>
                <th scope="col" className="py-3 px-6">
                  Name
                </th>
                <th scope="col" className="py-3 px-6">
                  Price
                </th>
                <th scope="col" className="py-3 px-6">
                  Discount Price
                </th>
                <th scope="col" className="py-3 px-6">
                  Description
                </th>
                <th scope="col" className="py-3 px-6">
                  Allergies
                </th>
                <th scope="col" className="py-3 px-6">
                  Type
                </th>
                <th scope="col" className="py-3 px-6">
                  Vegan
                </th>
                <th scope="col" className="py-3 px-6">
                  Home Made
                </th>
                <th scope="col" className="py-3 px-6">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {paginatedMenu.length > 0 ? (
                paginatedMenu.map((item) => (
                  <tr
                    key={item._id}
                    className="bg-white border-b dark:bg-white-800 dark:border-white-700"
                  >
                    <td className="py-4 px-6">{item.name}</td>
                    <td className="py-4 px-6">{item.price}</td>
                    <td className="py-4 px-6">{item.dprice}</td>
                    <td className="py-4 px-6">{item.description}</td>
                    <td className="py-4 px-6">{item.allergies}</td>
                    <td className="py-4 px-6">{item.isType}</td>
                    <td className="py-4 px-6">{item.baigan}</td>
                    <td className="py-4 px-6">
                      {item.home === "Yes" ? "Yes" : "No"}
                    </td>
                    <td className="py-4 px-6 flex gap-2">
                      <FaEdit
                        onClick={() => handleEdit(item)}
                        className="text-blue-600 cursor-pointer hover:text-blue-800"
                        title="Edit"
                        size={20}
                      />
                      <FaTrash
                        onClick={() => !allDeleted && handleDelete(item)} // Prevent action if allDeleted is true
                        className={`text-red-600 cursor-pointer hover:text-gray-800 ${
                          allDeleted ? "text-gray-500 cursor-not-allowed" : ""
                        }`}
                        title="Delete"
                        size={20}
                        disabled={allDeleted}
                      />
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="4" className="text-center py-4">
                    <span className="text-gray-500 text-lg font-semibold italic">
                      No data found
                    </span>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>

        {/* Pagination controls */}
        <div className="flex justify-center mt-4">
          <button
            disabled={currentPage === 1 || paginatedMenu.length === 0} // Prevent going back if already on first page
            onClick={() => setCurrentPage((prev) => prev - 1)}
            className="px-3 py-1 border rounded-l-md bg-gray-200 hover:bg-gray-300 disabled:opacity-50"
          >
            Previous
          </button>
          <span className="px-4 py-1 border bg-gray-100">{currentPage}</span>
          <button
            disabled={
              currentPage * itemsPerPage >= filteredMenu.length ||
              paginatedMenu.length === 0
            } // Prevent going forward if no more items
            onClick={() => setCurrentPage((prev) => prev + 1)}
            className="px-3 py-1 border rounded-r-md bg-gray-200 hover:bg-gray-300 disabled:opacity-50"
          >
            Next
          </button>
        </div>
      </div>
    );
  };

  const [uploadModalOpen, setUploadModalOpen] = useState(false);
  const [file, setFile] = useState(null);
  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };
  const handleFileUpload = async () => {
    if (!file) {
      toast.error("Please select a file!");
      return;
    }

    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER}/api/dish/menu-file-upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      toast.success("Menu uploaded successfully!");
      fetchMenus();
      setUploadModalOpen(false);
    } catch (error) {
      toast.error("Error uploading the menu!");
      console.error(error);
    }
  };

  const [excel, setExcel] = useState(false);

  const download = () => {
    setExcel(true);
  };
  const downloadSampleFile = async () => {
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER}/dish-download`,
        { responseType: "blob" }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "sample-dish.xlsx");
      document.body.appendChild(link);
      link.click();
      link.remove();
      toast.success("Download Successfully");
      setExcel(false);
    } catch (error) {
      console.error("Error downloading the sample file:", error);
      toast.error("Internal Server Error");
    }
  };

  return (
    <div>
      <Navbar />
      <div className="p-4 sm:ml-64">
        <div className="p-4 border-gray-200 rounded-lg dark:border-gray-700 mt-14">
          {/* Button Group */}
          {excel && (
            <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center z-50">
              <div className="bg-white dark:bg-gray-200 p-6 rounded-lg w-96">
                <div className="flex justify-between items-center mb-4">
                  <h2 className="text-lg font-semibold">
                    Upload Dish as Excel
                  </h2>
                  <button
                    onClick={() => setExcel(false)}
                    className="text-black hover:text-gray-800"
                  >
                    ✕
                  </button>
                </div>
                <p className="text-sm mb-4 text-black">
                  Please upload the dish details as an Excel file. Ensure that
                  the fields are formatted correctly:
                </p>
                <ul className="list-disc list-inside text-sm text-black dark:text-black">
                  <li>
                    <strong>Date:</strong> YYYY-MM-DD format
                  </li>
                  <li>
                    <strong>Name:</strong> Dish name (required)
                  </li>
                  <li>
                    <strong>Allergies:</strong> Optional allergy details
                  </li>
                  <li>
                    <strong>Price:</strong> Dish price (required, number)
                  </li>
                  <li>
                    <strong>Description:</strong> Dish description (required)
                  </li>
                  <li>
                    <strong>Discounted Price:</strong> Required, number
                  </li>
                  <li>
                    <strong>Type:</strong> Defaults to "dessert" if not provided
                  </li>
                </ul>
                <button
                  onClick={downloadSampleFile}
                  className="mt-4 w-full px-4 py-2 text-white bg-black rounded-md"
                >
                  Download Sample Excel
                </button>
              </div>
            </div>
          )}

          <div className="w-full flex flex-col sm:flex-row sm:flex-wrap items-center py-4 gap-4">
            {/* Add Menu Button */}
            <button
              onClick={() => setAddMenuModalOpen(true)}
              className="px-6 py-2 text-white bg-gray-800 dark:bg-gray-700 rounded-full hover:bg-gray-600 transition-all duration-300 w-full sm:w-auto"
            >
              Add Menu
            </button>

            {/* Upload Dish as Excel Button */}
            <button
              onClick={() => setUploadModalOpen(true)}
              className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0]"
            >
              Upload Dish as Excel
            </button>

            {/* Download Sample File Button */}
            <button
              onClick={download}
              className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0]"
            >
              Download Sample File
            </button>

            {/* Delete All Items Button */}
            <button
              onClick={handleDeleteAllModalOpen}
              className={`px-6 py-2 text-white ${
                allDeleted
                  ? "bg-gray-500 cursor-not-allowed"
                  : "bg-gray-600 hover:bg-red-500"
              } dark:bg-gray-700 dark:hover:bg-gray-500 rounded-full transition-all duration-300 w-full sm:w-auto`}
              disabled={allDeleted}
            >
              {allDeleted ? "All items deleted" : "Delete All Items"}
            </button>
          </div>

          {/* Search Input */}
          <div className="mt-6">
            <input
              type="text"
              value={searchQuery}
              onChange={handleSearchChange}
              placeholder="Search menu..."
              className="px-4 py-2 border-4 border-gray-600 dark:border-gray-500 rounded-lg shadow-md w-full"
            />
          </div>

          {/* Render the Menu Table */}
          <div className="mt-6">{menus && renderTable(menus)}</div>
        </div>

        {/* Delete Confirmation Modal */}
        {isDeleteModalOpen && (
          <div className="fixed inset-0 flex items-center justify-center bg-gray-500 bg-opacity-50 z-50">
            <div className="bg-white dark:bg-gray-600 p-6 rounded-lg shadow-lg w-full max-w-sm">
              <h2 className="text-xl font-semibold text-center mb-4 text-white">
                Are you sure?
              </h2>
              <p className="text-center text-white dark:text-white mb-6">
                This will delete all menu items permanently.
              </p>
              <div className="flex justify-center gap-4">
                {/* Confirm Deletion */}
                <button
                  onClick={handleDeleteAll} // Trigger deletion on confirmation
                  className="px-4 py-2 bg-gray-100 text-black rounded-full hover:bg-gray-500 transition-all"
                >
                  Yes, Delete All
                </button>
                {/* Close the modal */}
                <button
                  onClick={() => setIsDeleteModalOpen(false)} // Close modal if cancel is clicked
                  className="px-4 py-2 bg-black text-white rounded-full hover:bg-gray-500 transition-all"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        )}
      </div>

      {isAddMenuModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-2xl p-10 overflow-y-auto max-h-[80vh] relative">
            {/* Close Button */}
            <button
              onClick={() => setAddMenuModalOpen(false)}
              className="absolute top-4 right-4 text-3xl font-bold text-gray-500 hover:text-gray-700"
            >
              &times;
            </button>

            <h2 className="text-xl font-bold mb-4">Add Daily Menu</h2>
            <form onSubmit={handleSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Date
                </label>
                <input
                  type="text"
                  name="date"
                  value={menuData.date}
                  readOnly
                  className="mt-1 block w-full border border-black rounded-md shadow-sm p-3 bg-gray-100"
                />
              </div>

              {/* Dish Section */}
              <h3 className="text-lg font-semibold">Dishes</h3>
              {menuData.dishes.map((dish, index) => (
                <div key={index} className="grid grid-cols-1 gap-4 mb-5">
                  <label className="font-bold">{`Dish-${index + 1}`}</label>
                  <input
                    type="text"
                    placeholder={`Dish-${index + 1} Name`}
                    value={dish.name}
                    onChange={(e) =>
                      handleDishChange(index, "name", e.target.value, "dishes")
                    }
                    className="border rounded-md p-3 w-full border border-black"
                    required
                  />

                  <div className="mb-4">
                    <input
                      type="number"
                      placeholder={`Dish-${index + 1} Price`}
                      value={dish.price}
                      onChange={(e) => {
                        const value =
                          e.target.value === ""
                            ? ""
                            : Math.max(0, parseFloat(e.target.value) || 0); // Allow clearing and no negative values
                        handleDishChange(index, "price", value, "dishes");
                      }}
                      onBlur={() => {
                        // Ensure price is set to a valid number on blur
                        handleDishChange(
                          index,
                          "price",
                          dish.price === ""
                            ? 0
                            : Math.max(0, parseFloat(dish.price) || 0),
                          "dishes"
                        );
                      }}
                      className={`border rounded-md p-3 w-full ${
                        dish.price === "" || parseFloat(dish.price) <= 0
                          ? "border-red-500"
                          : "border-black"
                      }`}
                    />
                    {dish.price === "" || parseFloat(dish.price) <= 0 ? (
                      <p className="text-red-500 text-sm mt-1">
                        Price must be greater than zero.
                      </p>
                    ) : null}

                    <input
                      type="number"
                      placeholder={`Dish-${index + 1} Discount Price`}
                      value={dish.dprice}
                      onChange={(e) => {
                        const value =
                          e.target.value === ""
                            ? ""
                            : Math.max(0, parseFloat(e.target.value) || 0); // Allow clearing and no negative values
                        handleDishChange(index, "dprice", value, "dishes");
                      }}
                      onBlur={() => {
                        // Ensure discount price doesn't exceed price on blur
                        handleDishChange(
                          index,
                          "dprice",
                          dish.dprice === ""
                            ? 0
                            : Math.min(
                                Math.max(0, parseFloat(dish.dprice) || 0),
                                parseFloat(dish.price) || 0
                              ),
                          "dishes"
                        );
                      }}
                      className={`border rounded-md p-3 mt-4 w-full ${
                        parseFloat(dish.dprice) > parseFloat(dish.price) ||
                        dish.dprice === "" ||
                        parseFloat(dish.dprice) < 0
                          ? "border-red-500"
                          : "border-black"
                      }`}
                    />
                    {parseFloat(dish.dprice) > parseFloat(dish.price) ? (
                      <p className="text-red-500 text-sm mt-1">
                        Discount price cannot exceed the main price.
                      </p>
                    ) : null}
                    {dish.dprice === "" || parseFloat(dish.dprice) < 0 ? (
                      <p className="text-red-500 text-sm mt-1">
                        Discount price must be zero or greater.
                      </p>
                    ) : null}
                  </div>

                  <input
                    type="text"
                    placeholder={`Dish-${index + 1} Description`}
                    value={dish.description}
                    onChange={(e) =>
                      handleDishChange(
                        index,
                        "description",
                        e.target.value,
                        "dishes"
                      )
                    }
                    className="border rounded-md p-3 w-full border border-black"
                    required
                  />
                  <input
                    type="text"
                    placeholder={`Dish-${index + 1} Allergies`}
                    value={dish.allergies}
                    onChange={(e) =>
                      handleDishChange(
                        index,
                        "allergies",
                        e.target.value,
                        "dishes"
                      )
                    }
                    className="border rounded-md p-3 w-full border border-black"
                  />
                  <div className="mb-4">
                    <label className="block text-sm font-medium text-gray-700">
                      {`Dish-${index + 1} Vegan`}
                    </label>
                    <div className="mt-1">
                      <input
                        type="checkbox"
                        checked={dish.baigan === "Yes"} // Checked if value is "Yes"
                        onChange={(e) =>
                          handleDishChange(
                            index,
                            "baigan",
                            e.target.checked ? "Yes" : "No",
                            "dishes"
                          )
                        }
                        className="mr-2 h-4 w-4 border border-gray-300 rounded text-blue-600 focus:ring-blue-500"
                      />
                      <span>Check if Vegan</span>
                    </div>
                  </div>

                  <div className="mb-6">
                    <label className="block text-gray-700 text-lg mb-2 flex items-center">
                      <span className="mr-3">Dish-{index + 1} Home Made?</span>
                      <input
                        type="checkbox"
                        checked={dish.home === "Yes"}
                        onChange={(e) =>
                          handleDishChange(
                            index,
                            "home",
                            e.target.checked ? "Yes" : "No",
                            "dishes"
                          )
                        }
                        className="h-5 w-5 border border-black rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                      />
                    </label>
                  </div>
                  {index > 0 && (
                    <button
                      type="button"
                      onClick={() => removeRow(index, "dishes")}
                      className="text-red-500 mt-2"
                    >
                      Remove
                    </button>
                  )}
                </div>
              ))}
              <button
                type="button"
                onClick={() => addNewRow("dishes")}
                className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0] p-4 mb-5"
                disabled={menuData.dishes.length >= 3}
              >
                Add Another Dish
              </button>

              {/* Dessert Section */}
              <h3 className="text-lg font-semibold mb-2">Dessert</h3>
              {menuData.desserts.length === 1 && (
                <div className="grid grid-cols-1 gap-4 mb-2">
                  <input
                    type="text"
                    placeholder="Dessert Name"
                    value={menuData.desserts[0].name}
                    onChange={(e) =>
                      handleDishChange(0, "name", e.target.value, "desserts")
                    }
                    className="border rounded-md p-3 w-full border border-black"
                    required
                  />
                  <input
                    type="number"
                    placeholder="Dessert Price"
                    value={menuData.desserts[0].price}
                    onChange={(e) => {
                      const value =
                        e.target.value === ""
                          ? ""
                          : Math.max(0, parseFloat(e.target.value) || 0); // Ensure non-negative
                      handleDishChange(0, "price", value, "desserts");
                    }}
                    onBlur={() => {
                      // Set a valid price if the field is cleared or invalid
                      const value =
                        menuData.desserts[0].price === ""
                          ? 0
                          : Math.max(
                              0,
                              parseFloat(menuData.desserts[0].price) || 0
                            );
                      handleDishChange(0, "price", value, "desserts");
                    }}
                    className={`border rounded-md p-3 w-full ${
                      menuData.desserts[0].price === "" ||
                      parseFloat(menuData.desserts[0].price) <= 0
                        ? "border-red-500"
                        : "border-black"
                    }`}
                  />
                  {menuData.desserts[0].price === "" ||
                  parseFloat(menuData.desserts[0].price) <= 0 ? (
                    <p className="text-red-500 text-sm mt-1">
                      Dessert price must be greater than zero.
                    </p>
                  ) : null}

                  <input
                    type="number"
                    placeholder="Dessert Discount Price"
                    value={menuData.desserts[0].dprice}
                    onChange={(e) => {
                      const value =
                        e.target.value === ""
                          ? ""
                          : Math.max(0, parseFloat(e.target.value) || 0); // Ensure non-negative
                      handleDishChange(0, "dprice", value, "desserts");
                    }}
                    onBlur={() => {
                      // Ensure discount price is valid and doesn't exceed the price
                      const dprice =
                        menuData.desserts[0].dprice === ""
                          ? 0
                          : Math.min(
                              Math.max(
                                0,
                                parseFloat(menuData.desserts[0].dprice) || 0
                              ),
                              parseFloat(menuData.desserts[0].price) || 0
                            );
                      handleDishChange(0, "dprice", dprice, "desserts");
                    }}
                    className={`border rounded-md p-3 w-full ${
                      parseFloat(menuData.desserts[0].dprice) >
                        parseFloat(menuData.desserts[0].price) ||
                      menuData.desserts[0].dprice === "" ||
                      parseFloat(menuData.desserts[0].dprice) < 0
                        ? "border-red-500"
                        : "border-black"
                    }`}
                  />
                  {parseFloat(menuData.desserts[0].dprice) >
                  parseFloat(menuData.desserts[0].price) ? (
                    <p className="text-red-500 text-sm mt-1">
                      Discount price cannot exceed the dessert price.
                    </p>
                  ) : null}
                  {menuData.desserts[0].dprice === "" ||
                  parseFloat(menuData.desserts[0].dprice) < 0 ? (
                    <p className="text-red-500 text-sm mt-1">
                      Discount price must be zero or greater.
                    </p>
                  ) : null}

                  <input
                    type="text"
                    placeholder="Dessert Description"
                    value={menuData.desserts[0].description}
                    onChange={(e) =>
                      handleDishChange(
                        0,
                        "description",
                        e.target.value,
                        "desserts"
                      )
                    }
                    className="border rounded-md p-3 w-full border border-black"
                    required
                  />
                  <input
                    type="text"
                    placeholder="Allergies"
                    value={menuData.desserts[0].allergies}
                    onChange={(e) =>
                      handleDishChange(
                        0,
                        "allergies",
                        e.target.value,
                        "desserts"
                      )
                    }
                    className="border rounded-md p-3 w-full border border-black"
                  />
                  <div className="mb-4">
                    <label className="block text-gray-700 text-lg mb-2 flex items-center">
                      <span className="mr-3">Vegan?</span>
                      <input
                        type="checkbox"
                        checked={menuData.desserts[0].baigan === "Yes"} // Checked if value is "Yes"
                        onChange={(e) =>
                          handleDishChange(
                            0,
                            "baigan",
                            e.target.checked ? "Yes" : "No",
                            "desserts"
                          )
                        }
                        className="h-5 w-5 border border-black rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                      />
                    </label>
                  </div>

                  <div className="mb-6">
                    <label className="block text-gray-700 text-lg mb-2 flex items-center">
                      <span className="mr-3">Home Made?</span>
                      <input
                        type="checkbox"
                        checked={menuData.desserts[0].home === "Yes"}
                        onChange={(e) =>
                          handleDishChange(
                            0,
                            "home",
                            e.target.checked ? "Yes" : "No",
                            "desserts"
                          )
                        }
                        className="h-5 w-5 border border-black rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                      />
                    </label>
                  </div>

                  <button
                    type="button"
                    onClick={() => removeRow(0, "desserts")}
                    className="text-red-500 mt-2"
                  >
                    Remove
                  </button>
                </div>
              )}
              {menuData.desserts.length === 0 && (
                <button
                  type="button"
                  onClick={() => addNewRow("desserts")}
                  className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0]"
                >
                  Add Dessert
                </button>
              )}

              {/* Submit */}
              <div className="flex justify-end mt-4">
                <button
                  type="button"
                  className="px-4 py-2 bg-black text-white rounded-md mr-2"
                  onClick={() => setAddMenuModalOpen(false)}
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0]"
                >
                  Save
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {isEditMenuModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50 z-50">
          <div className="bg-white rounded-lg shadow-lg w-full max-w-sm sm:max-w-md md:max-w-sm lg:max-w-md xl:max-w-lg p-10 overflow-y-auto max-h-[90vh]">
            <h2 className="text-2xl font-bold mb-6 text-center">Edit Menu</h2>
            <form>
              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">Name</label>
                <input
                  type="text"
                  name="name"
                  value={editmenuData.name}
                  onChange={handleEditChange}
                  className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Price
                </label>
                <input
                  type="number"
                  name="price"
                  value={editmenuData.price}
                  onChange={(e) => {
                    const value =
                      e.target.value === ""
                        ? ""
                        : Math.max(0, parseFloat(e.target.value) || 0); // Ensure non-negative
                    editsetMenuData((prev) => ({
                      ...prev,
                      price: value,
                    }));
                  }}
                  onBlur={() => {
                    // Ensure price is valid on blur
                    const value =
                      editmenuData.price === ""
                        ? 0
                        : Math.max(0, parseFloat(editmenuData.price) || 0);
                    editsetMenuData((prev) => ({
                      ...prev,
                      price: value,
                    }));
                  }}
                  className={`w-full border p-3 rounded focus:outline-none focus:ring-2 ${
                    editmenuData.price === "" ||
                    parseFloat(editmenuData.price) <= 0
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                />
                {editmenuData.price === "" ||
                parseFloat(editmenuData.price) <= 0 ? (
                  <p className="text-red-500 text-sm mt-1">
                    Price must be greater than zero.
                  </p>
                ) : null}
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Discount Price
                </label>
                <input
                  type="number"
                  name="dprice"
                  value={editmenuData.dprice}
                  onChange={(e) => {
                    const value =
                      e.target.value === ""
                        ? ""
                        : Math.max(0, parseFloat(e.target.value) || 0); // Ensure non-negative
                    editsetMenuData((prev) => ({
                      ...prev,
                      dprice: value,
                    }));
                  }}
                  onBlur={() => {
                    // Ensure discount price is valid on blur
                    const dprice =
                      editmenuData.dprice === ""
                        ? 0
                        : Math.min(
                            Math.max(0, parseFloat(editmenuData.dprice) || 0),
                            parseFloat(editmenuData.price) || 0
                          );
                    editsetMenuData((prev) => ({
                      ...prev,
                      dprice: dprice,
                    }));
                  }}
                  className={`w-full border p-3 rounded focus:outline-none focus:ring-2 ${
                    parseFloat(editmenuData.dprice) >
                      parseFloat(editmenuData.price) ||
                    editmenuData.dprice === "" ||
                    parseFloat(editmenuData.dprice) < 0
                      ? "border-red-500"
                      : "border-gray-300"
                  }`}
                />
                {parseFloat(editmenuData.dprice) >
                parseFloat(editmenuData.price) ? (
                  <p className="text-red-500 text-sm mt-1">
                    Discount price cannot exceed the main price.
                  </p>
                ) : null}
                {editmenuData.dprice === "" ||
                parseFloat(editmenuData.dprice) < 0 ? (
                  <p className="text-red-500 text-sm mt-1">
                    Discount price must be zero or greater.
                  </p>
                ) : null}
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Description
                </label>
                <textarea
                  name="description"
                  value={editmenuData.description}
                  onChange={handleEditChange}
                  className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                ></textarea>
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Allergies
                </label>
                <input
                  type="text"
                  name="allergies"
                  value={editmenuData.allergies}
                  onChange={handleEditChange}
                  className="w-full border border-gray-300 p-3 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                />
              </div>
              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2">
                  Vegan
                </label>
                <div>
                  <input
                    type="checkbox"
                    name="baigan"
                    checked={editmenuData.baigan === "Yes"} // Checked if value is "Yes"
                    onChange={(e) =>
                      handleEditChange({
                        target: {
                          name: "baigan",
                          value: e.target.checked ? "Yes" : "No",
                        },
                      })
                    }
                    className="mr-2 h-5 w-5 border border-gray-300 rounded text-blue-600 focus:ring-blue-400"
                  />
                  <span>Check if Vegan</span>
                </div>
              </div>

              <div className="mb-6">
                <label className="block text-gray-700 text-lg mb-2 flex items-center">
                  <span className="mr-3">Home Made?</span>
                  <input
                    type="checkbox"
                    checked={editmenuData.home === "Yes"}
                    onChange={(e) =>
                      handleEditChange({
                        target: {
                          name: "home",
                          value: e.target.checked ? "Yes" : "No",
                        },
                      })
                    }
                    className="h-5 w-5 border border-gray-300 rounded focus:outline-none focus:ring-2 focus:ring-blue-400"
                  />
                </label>
              </div>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={() => setEditMenuModalOpen(false)}
                  className="px-4 py-2 bg-black text-white rounded-md mr-2"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  onClick={handleEditSubmit}
                  className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0] "
                >
                  Save Changes
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      {uploadModalOpen && (
        <div className="fixed inset-0 flex justify-center items-center bg-gray-900 bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-1/2">
            <h3 className="text-xl font-medium mb-4">
              Upload Menu as Excel File
            </h3>
            <input
              type="file"
              accept=".xlsx,.xls"
              onChange={handleFileChange}
              className="mb-4"
            />
            <div className="flex justify-between">
              <button
                onClick={() => setUploadModalOpen(false)}
                className="bg-gray-500 text-white px-4 py-2 rounded"
              >
                Close
              </button>
              <button
                onClick={handleFileUpload}
                className="px-4 py-2 text-black rounded-md bg-[#B1D4E0]-100 dark:bg-[#B1D4E0] "
              >
                Upload
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default DishDescription;
